import { Link } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import Callout from '../components/Callout';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import SurveyCallout from '../components/SurveyCallout';
import Video from '../components/Video';
import '../styles/pages/__about.scss';

const textOverlay = (
  <div className="about__text_overlay_container">
    <Container fullWidth>
      <div className="about__text_overlay_title">About Discover Data</div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    <span className="subfooter__highlighted-text">Data Equity</span> includes
    enabling students in underrepresented communities to have equal access to
    the data revolution.
  </span>
);

const AboutPage = ({ data }) => (
  <Layout title="About the Program">
    <Hero mediaSource="img-lead-triangle-about.png" textOverlay={textOverlay} />
    <Section className="about__callout-section section--gray">
      <Container fullWidth>
        <Row className="about__callout-row">
          <Column largeOffset={1} large={10}>
            <Callout
              className="about__callout"
              containerClassName="about__callout-container"
            >
              Big data, bigger possibilities
            </Callout>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className={'section--gray'}>
      {/* INTRO */}
      <Container fullWidth>
        <Row>
          <Column size={9} className="text-sans-serif">
            <p>
              Discover Data is a 21st Century education initiative powered by
              the Nielsen Foundation in collaboration with Discovery Education
              and the National AfterSchool Association that introduces students
              across the country to relatable, real-world applications of data
              and the professionals using that data to innovate for the future.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <Video
              guidSrc="327c8531-ef6c-4b85-acd6-409ea6d8ad30"
              poster="about-video-poster@2x.jpg"
            />
          </Column>
          <Column size={6} className="text-sans-serif">
            <h2>
              Break down problems and find better ways forward using data.
            </h2>
            <p className="pr-1.5">
              Our global world is increasingly dependent on the universal
              language of data. Join us as we give students nationwide a voice
              in this ever-evolving conversation, and empower a new generation
              of data-driven thinkers to shape the future. Access
              standards-aligned resources that show students how to turn their
              ideas into action through the unlimited potential of data.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container fullWidth className={'about__images'}>
        <Row>
          <Column size={9}>
            <h2>Background on Discover Data and DE digital learning</h2>
          </Column>
        </Row>
        <Row className="pb-1.5">
          {/* Educational Design */}
          <Column size={4}>
            <Image filename="about-ed-design@2x.jpg" />
          </Column>
          <Column size={8} className="about__background text-sans-serif">
            <h3>Educational design</h3>
            <p>
              Discovery Education uses the research-based Universal Design for
              Learning (UDL) framework to develop standards-aligned high quality
              digital content to provide differentiated and equitable access to
              education for diverse student audiences. Review this whitepaper to
              Learn more about Discovery Education’s pedagogical philosophy,
              used to design the resources available on this site.
            </p>
            <Button
              to="/sites/default/files/documents/DE-Platform-Leveraging-Digital-Content-White-Paper.pdf"
              target="_blank"
              rel="noopener noreferrer"
              link={true}
            >
              <span className="about__education-design-link">Learn more</span>
            </Button>
          </Column>
        </Row>
        <Row className="pb-1.5">
          {/* Reach */}
          <Column size={4}>
            <Image filename="about-reach@2x.jpg" />
          </Column>
          <Column size={8} className="about__background text-sans-serif">
            <h3>Reach</h3>
            <p>
              Since 2018, the Discover Data program has reached over 600,000
              students with data resources and educational tools. These tools
              can help to improve student understanding of the role that data
              plays in daily life and student interest in learning more about
              data-centered careers.
            </p>
          </Column>
        </Row>
        <Row className="pb-1.5">
          {/* Student Learning */}
          <Column size={4}>
            <Image filename="about-student@2x.jpg" />
          </Column>
          <Column size={8} className="about__background text-sans-serif">
            <h3>Student learning</h3>
            <p>
              Discovery Education has conducted several research studies on its
              streaming platform, which now offers the Discover Data curriculum.
              These studies show that students in classrooms that frequently use
              DE digital resources have higher levels of engagement and
              achievement compared to their peers in classrooms with little or
              no use of these resources.
            </p>
            <p>
              Additionally, external research suggests that access to Discovery
              Education digital resources helps to aid students’ scientific
              understanding and exposes them to future academic and career
              opportunities in science.
            </p>
            <Button
              to="/sites/default/files/Curriculum/DE_STREAMING_RESULTS_2019.pdf"
              target="_blank"
              rel="noopener noreferrer"
              link={true}
            >
              <span className="about__education-design-link">Learn more</span>
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="about__partners text-sans-serif">
      {/* About the Partners */}
      <Container fullWidth>
        <h2>About the partners</h2>
        <Row className="pt-1.5">
          <Column size={3}>
            <Image
              filename="logo-nielsen-foundation.svg"
              className="about__logo-nielsen"
            />
          </Column>
          <Column size={9}>
            <p>
              The Nielsen Foundation envisions a more equitable world, enabled
              by inclusive media and technology, where everyone has voice and
              opportunities to succeed. The foundation's grantmaking is focused
              on powering and advancing inclusive innovation and representation
              in the media and technology industries, especially through the use
              of data and research. The foundation supports organizations that
              drive greater representation, inclusion and equity in media and
              technology, including initiatives that use data in innovative ways
              to advance media and technology as forces for good. The Nielsen
              Foundation is a private foundation originally funded by Nielsen, a
              global data and analytics company.
            </p>
          </Column>
        </Row>
        <Row className="pt-3">
          <Column size={3}>
            <Image
              filename="de-logo-positive.png"
              className="about__logo-de mt-0.5"
            />
          </Column>
          <Column size={9}>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in nearly 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at{' '}
              <Link to="https://www.discoveryeducation.com">
                www.discoveryeducation.com
              </Link>
            </p>
          </Column>
        </Row>
        <Row className="pt-3">
          <Column size={3}>
            <Image
              filename="logo-naa@3x.svg"
              className="about__logo-naa mt-0.5"
            />
          </Column>
          <Column size={9}>
            <p>
              The National AfterSchool Association (NAA) is the membership
              association for professionals who work with children and youth in
              diverse school and community-based settings to provide a wide
              variety of extended learning opportunities and care during
              out-of-school hours. The mission of the NAA is to foster
              development, provide education, and encourage advocacy for the
              out-of-school-time community. As the national membership
              organization for professionals who work with and on behalf of
              children and youth during out-of-school time, the NAA provides a
              voice for the afterschool profession. We exist to inspire,
              connect, and equip afterschool professionals.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <SurveyCallout />
    <Section className="pt-0 pb-0">
      <Subfooter
        img="footer-about@2x.jpg"
        content={subfooterContent}
        icon="icon-subject.svg"
      />
    </Section>
  </Layout>
);

export default AboutPage;
